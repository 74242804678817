// import { useKeycloak } from '@react-keycloak/web';

import { cloneDeep } from 'lodash';
import { Feature, Map, MapBrowserEvent, Overlay, View } from 'ol';
import { Coordinate } from 'ol/coordinate';
import { /*shiftKeyOnly,*/ singleClick } from 'ol/events/condition';
// import { getCenter } from 'ol/extent';
import GeoJSON from 'ol/format/GeoJSON';
import { Geometry, LineString } from 'ol/geom';
import Circle from 'ol/geom/Circle';
import { Type } from 'ol/geom/Geometry';
import { defaults, DragPan, Draw, Modify, Select, Snap, Translate } from 'ol/interaction';
import { createBox, GeometryFunction } from 'ol/interaction/Draw';
import { SelectEvent } from 'ol/interaction/Select';
import { TranslateEvent } from 'ol/interaction/Translate';
import Layer from 'ol/layer/Layer';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import { fromLonLat, Projection, toLonLat, transform } from 'ol/proj';
import { register } from 'ol/proj/proj4';
import VectorSource from 'ol/source/Vector';
import XYZ from 'ol/source/XYZ';
import Fill from 'ol/style/Fill';
// import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
//@ts-ignore
import CanvasFilter from 'ol-ext/filter/CanvasFilter';
import proj4 from 'proj4';
import React, { FC, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useDispatch, useSelector } from 'react-redux';
import { DraggableData, Position, ResizableDelta, Rnd } from 'react-rnd';
import { v4 } from 'uuid';

import { ModeEnum } from '../../core/ui/enums/ModeEnum';
import { PlaybackEnum } from '../../core/ui/enums/PlaybackEnum';
import { fromGeoBoxToBounds } from '../../helpers/boundsManage';
import { distanceBetweenPoints, getMinusZoom } from '../../helpers/distanceBetweenPoints';
import { getCurrentMapPosition, getPlayKeyframes, shouldFly } from '../../helpers/getKeyframes';
import { useHideMapOnLastSkip } from '../../hooks/useHideMapOnLastSkip';
import { useOverlap } from '../../hooks/useOverlap';
import { useScaleFactor } from '../../hooks/useScaleFactor';
import { useWindowResize } from '../../hooks/useWindowResize';
import {
  EARTH_RADIUS,
  MAX_FULLSCREEN_HEIGHT,
  OCEAN_NSPER_COORDINATE,
  SOUTH_POLE_NSPER_COORDINATE,
  STUDIO_PANEL_HEIGHT,
} from '../../model/constants/constants';
import { C9ProjectDef } from '../../model/definitions/C9ProjectDef';
import { isCopyDrawingInput } from '../../model/definitions/CopyDrawingInput';
import { CustomVectorLayer } from '../../model/definitions/CustomVectorLayer';
// import { C9ProjectDef } from '../../model/definitions/C9ProjectDef';
import { DrawingDef } from '../../model/definitions/DrawingDef';
import { ForecastWDElementDef } from '../../model/definitions/ForecastWDElementDef';
import { MapPanelDef } from '../../model/definitions/MapPanelDef';
import { ObservedWDElementDef } from '../../model/definitions/ObservedWDElementDef';
import { PositionControlDef } from '../../model/definitions/PositionControlDef';
import { VectorMapLayer } from '../../model/definitions/VecorMapLayer';
import { AnimationsEnum } from '../../model/enums/AnimationsEnum';
import { DrawingTypeEnum, DrawingTypeExternalEnum } from '../../model/enums/DrawingTypeEnum';
import { GlobalPlayerControl } from '../../pages/playground/GlobalPlayerControl';
import { MapsRenderCache } from '../../pages/playground/MapsRenderCache';
import PlayerContext from '../../pages/playground/playerContext/PlayerContext';
import {
  ActiveDef,
  setActiveDraw,
  setActiveMap,
  setElement,
  setLayerType,
  setMapsRendered,
  setSymbolPoint,
  updateActiveDrawEdit,
} from '../../store/slices/active-slice';
import { updateMapState } from '../../store/slices/map-state.slice';
import {
  /*copyMapDrawingLayer,*/ copyMapDrawingLayer,
  updateMapLayer,
} from '../../store/slices/project-slice';
import { selectSkips } from '../../store/slices/selectors';
import { RootState } from '../../store/store';
import { ForecastElement, ObservedElement } from '../canvasElements';
import CityPosterLayerFontContainer from '../canvasElements/CityPosterLayerFontContainer';
import { PosterElement } from '../canvasElements/PosterElement';
import style from '../canvasElements/style.module.scss';
import SymbolLayerFontContainer from '../canvasElements/SymbolLayerContainer';
import { transformAbsoluteToPercent, transformPercentToAbsolute } from '../canvasElements/utils';
import {
  geometryFunctionForCurvedClosedLine,
  getSmoothLineString,
  getTranslatedCoordinates,
  setFeatureProperties,
} from './drawHeplers';
import {
  cacheCoordinate,
  cacheMapClick,
  getCachedCoordinate,
  getMapClickCoordinate,
} from './DrawingSelectedPointsCache';
import { getProjections } from './getProjections';
import {
  addBaseVectorLayer,
  addCircleToGeoJson,
  addCustomVectorLayer,
  cityStyle,
  cityStyleSelect,
  cleanUpCustomVectorLayers,
  createBaseMapTilesURL,
  distance,
  // createCircleGradient,
  drawCircleGradient,
  getFeatureById,
  getLayerByClassName,
  getOcean,
  getStyleFunctionDraw,
  handleDrawingLayersInteractions,
  handleGraticules,
  handleHighlightFeature,
  hasAnyWdLayer,
  isInterpolatedDrawingChanged,
  isZoomInteraction,
  renderVectorIsolines,
  zoomToZoomByRatio,
} from './helpers';
import styles from './MapElement.module.scss';
import nsper from './nsper';
import { SymbolLayerWeatherType } from './SymbolLayerWeatherType';
import Transition from './Transition';
import { useCityPosterLayers } from './useCityPosterLayers';
import useDrawingTools, {
  calculateDrawingKeyframeRefactor,
  calculateTimestampFromIndicator,
} from './useDrawingTools';
import { useEventLayers } from './useEventsLayers';
import { useSymbolLayers } from './useSymbolLayers';
import { useWeatherDataLayers } from './useWeatherDataLayers';

interface MapElementProps {
  canvas: { cnvWidth?: number; cnvHeight?: number };
  panelProps: MapPanelDef;
  onEdit: (e: MapPanelDef) => void;
  canvasRef?: React.MutableRefObject<HTMLDivElement | null>;
  disabled: boolean;
  start?: number;
  end?: number;
  scenesNumber: number;
  sceneId: string;
}

export const GVNSP_MASK_ZINDEX = 1000;

const MapElementBaseMerc = ({
  panelProps,
  onEdit,
  canvasRef,
  canvas,
  disabled,
  start,
  end,
  scenesNumber,
  sceneId,
}: MapElementProps) => {
  const dispatch = useDispatch();

  const shouldRenderMap =
    scenesNumber < 15 ||
    start === undefined ||
    end === undefined ||
    (GlobalPlayerControl.getTime() <= (end || 0) + 3000 &&
      GlobalPlayerControl.getTime() >= (start || 0) - 3000);

  const { bringToFront } = useOverlap(panelProps.positionControl, panelProps.id);
  const mapRefContainer = useRef<HTMLDivElement | null>(null);
  const mapRef = useRef<Map | null>(null);
  const filterRef = useRef<any>(null);
  const citySelectRef = useRef<Select | null>(null);
  const symbolPointSelectRef = useRef<Select | null>(null);
  const startTranslateCoordinateRef = useRef<Coordinate | null>(null);
  const drawSelectedFeatureRef = useRef<Feature | null>(null);
  const drawedLayerSelectedFeatureRef = useRef<Feature | null>(null);
  const translateRef = useRef<Translate | null>(null);
  const drawSourceRef = useRef<VectorSource<Geometry> | null>(null);
  const drawRef = useRef<Draw | null>(null);
  const snapInteractionsRef = useRef<Record<string, Snap>>({});
  const drawingLayersSelects = useRef<Record<string, Select>>({});
  const drawingLayersTranslates = useRef<Record<string, Translate>>({});
  const drawingLayersModifies = useRef<Record<string, Modify>>({});
  const counterOfMainCoordinates = useRef(0);
  const arrayOfTurningPoints = useRef<Coordinate[]>([]);
  const clickSelectInteractionRef = useRef<Select | null>(null);
  const scaleFactor = useScaleFactor(disabled);
  const hideMap = useHideMapOnLastSkip();
  const skips = useSelector<RootState, SkipTimeDef[]>((state) => selectSkips(state));
  const { cnvHeight } = canvas;
  // const {
  //   keycloak: { idTokenParsed },
  // } = useKeycloak();
  const [gridPosition, setPosition] = useState<Position & ResizableDelta>({
    x: panelProps.positionControl.x,
    y: panelProps.positionControl.y,
    width: panelProps.positionControl.w,
    height: panelProps.positionControl.y,
  });
  const [lines, setLines] = useState<boolean>(false);
  const hasAnyWdLayerValue = useMemo(() => {
    return hasAnyWdLayer(panelProps.wdSpace);
  }, [panelProps.wdSpace]);

  // Create a select interaction
  const {
    activeTime,
    activeAspectRatio,
    mode,
    activeElement,
    activeScene,
    oceanMask,
    projectToPlay,
    activeDraw,
    activeDrawEdit,
    activeDrawing,
    mapsAllowedZoom,
    activeFramerate,
  } = useSelector<RootState, ActiveDef>((state) => state.active);

  const isSequence = mode === ModeEnum.SEQUENCE;
  const scene = projectToPlay?.sceneDefs.find((scene) => scene.id === sceneId);
  const sceneTime = projectToPlay.sceneDefs.find((scene) => scene.id === sceneId)?.timeControl;
  const isSceneEnd = panelProps.timeControls[0].endMS + (scene?.hold || 0) === sceneTime?.endMS;
  const run = () => {
    return (
      !hideMap &&
      panelProps.timeControls.find((segment) => {
        return (
          segment.startMS <= contextValue.time &&
          segment.endMS + (isSceneEnd ? scene?.hold ?? 0 : 0) >= contextValue.time
        );
      })
    );
  };
  const isVisible = () => (!run() ? 'hidden' : 'visible');
  const project = useSelector<RootState, C9ProjectDef>((state) => state.project.present.project);
  const isJustAdded = Boolean(panelProps.isNew);

  const currentLayers = useMemo(() => {
    return panelProps.wdSpace[0].vectorMapLayers ?? [];
  }, [panelProps.wdSpace]);
  const symbolLayers = useMemo(() => {
    return panelProps.wdSpace[0].symbolLayers ?? [];
  }, [panelProps.wdSpace]);

  const projection = panelProps.baseMapSetup?.projection?.name
    ? panelProps.baseMapSetup?.projection?.name
    : 'EPSG:3857';
  const projString =
    panelProps.baseMapSetup.baseMapConfigurationProj4 ||
    '+proj=merc +a=6378137 +b=6378137 +lat_ts=0 +lon_0=0 +x_0=0 +y_0=0 +k=1 +units=m +nadgrids=@null +wktext +no_defs +type=crs';
  const isMercator = projString.includes('+proj=merc');
  const projectionCalc = `${projection}:${panelProps.id}`;
  const isGVNSP = projectionCalc.includes('ESRI:54049');
  const isUtm = projectionCalc.includes('EPSG:326');

  const projections = getProjections({ center: [0, 0], north: true });
  const projectionSettings = isUtm
    ? projections.find((p) => p.plainCode == 'UTM')
    : projections.find((p) => p.plainCode == projection);

  const projectionLike = useMemo(() => {
    return new Projection({
      code: projectionCalc,
      units: 'm',
      worldExtent: projectionSettings?.worldExtent,
    });
  }, [projectionCalc, projectionSettings]);

  if (projectionSettings) {
    projectionLike.setExtent(projectionSettings.extent);
  }

  const mapUrl = createBaseMapTilesURL(panelProps.baseMapSetup.mapStyle.name);
  const mapPosition = panelProps.mapPositionControl;
  const [coordinates, setCoordinates] = useState<PositionControlDef>({
    x: panelProps.positionControl.x ? panelProps.positionControl.x : 0,
    y: panelProps.positionControl.x ? panelProps.positionControl.y : 0,
    w: panelProps.positionControl.w ? panelProps.positionControl.w : 200,
    h: panelProps.positionControl.h ? panelProps.positionControl.h : 200,
    zindex: panelProps.positionControl.zindex ? panelProps.positionControl.zindex : 0,
    rotation: panelProps.positionControl.rotation ? panelProps.positionControl.rotation : 0,
  });

  const contextValue = useContext(PlayerContext);

  const baseLayer = useMemo(() => {
    if (mapRef.current) {
      const layers = mapRef.current.getLayers().getArray();
      return layers.find((layer) => layer instanceof TileLayer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapRef.current]);

  useEffect(() => {
    mapRef.current?.updateSize();
    disabled && mapRef.current?.getInteractions().forEach((i) => i.setActive(false));
    !disabled && mapRef.current?.getInteractions().forEach((i) => i.setActive(true));
  }, [cnvHeight, disabled, panelProps.id]);
  useEffect(() => {
    const ints = mapRef.current?.getInteractions();
    if (hasAnyWdLayer(panelProps.wdSpace) && !mapsAllowedZoom[panelProps.id]) {
      ints?.forEach((i) => {
        if (isZoomInteraction(i)) i.setActive(false);
      });
    } else if (!disabled) {
      ints?.forEach((i) => {
        if (isZoomInteraction(i)) i.setActive(true);
      });
    }
  }, [panelProps.id, panelProps.wdSpace, disabled, mapsAllowedZoom]);

  useEffect(() => {
    if (!mapRef.current) return;
    const isZoomEnabled = Boolean(mapsAllowedZoom[panelProps.id]) || !hasAnyWdLayerValue;

    const exisitingInteractions = mapRef.current.getInteractions().getArray();
    const hasDragPanInt = exisitingInteractions.some((i) => i instanceof DragPan);

    if (isZoomEnabled && !hasDragPanInt) {
      mapRef.current?.addInteraction(new DragPan());
    }
    if (!isZoomEnabled && hasDragPanInt) {
      mapRef.current?.getInteractions().forEach((i) => {
        if (i instanceof DragPan) {
          mapRef.current?.removeInteraction(i);
        }
      });
    }
  }, [mapsAllowedZoom, panelProps.id, hasAnyWdLayerValue]);
  /**Initialize map */
  useEffect(() => {
    if (mapRef.current && baseLayer) {
      baseLayer.setVisible(!panelProps.baseMapSetup.hidden);
    }
  }, [panelProps.baseMapSetup.hidden]);
  useEffect(() => {
    if (filterRef.current && panelProps.colorFilters) {
      filterRef.current.set('brightness', panelProps.colorFilters.brightness);
      filterRef.current.set('contrast', panelProps.colorFilters.contrast);
      filterRef.current.set('saturate', panelProps.colorFilters.saturate);
      filterRef.current.set('grayscale', panelProps.colorFilters.grayscale);
      filterRef.current.set('sepia', panelProps.colorFilters.sepia);
      filterRef.current.set('invert', panelProps.colorFilters.invert);
      filterRef.current.set('blur', panelProps.colorFilters.blur);
      filterRef.current.set('hueRotate', panelProps.colorFilters.hueRotate);
    }
  }, [panelProps.colorFilters]);
  useEffect(() => {
    handleHighlightFeature(
      mapRef.current!,
      activeDrawing.featureId,
      activeDrawing.drawingId,
      activeAspectRatio,
    );
  }, [activeDrawing, activeAspectRatio]);
  useWindowResize(() => {
    mapRef.current?.updateSize();
  }, []);
  useEffect(() => {
    // if (!shouldRenderMap) return;
    const storedMap = MapsRenderCache.get(panelProps.id);
    if (storedMap && shouldRenderMap) {
      /**Remove overlays */
      storedMap
        ?.getOverlays()
        .getArray()
        .filter((o) => !o.getElement()?.className.includes('overlay-weather-type'))
        .forEach((o) => {
          storedMap.removeOverlay(o);
          o.dispose();
        });
      storedMap.getInteractions().forEach((i) => {
        if (
          i instanceof DragPan ||
          i instanceof Select ||
          i instanceof Draw ||
          i instanceof Modify ||
          i instanceof Translate ||
          i instanceof Snap
        ) {
          storedMap.removeInteraction(i);
          i.dispose();
        }
      });
      mapRef.current = storedMap;
      mapRef.current.setTarget(mapRefContainer.current!);
      return;
    }

    // dispatch(resetMapLoading());

    if (!mapRef.current) {
      // @ts-ignore
      proj4.Proj.projections.add(nsper);

      proj4.defs(projectionCalc, projString);
      register(proj4);

      const baseMapSource = new XYZ({
        url: mapUrl,
        crossOrigin: 'anonymous',
        minZoom: isMercator ? undefined : 4,
        cacheSize: Infinity,
        transition: 0,
      });

      const baseLayer = new TileLayer({
        className: 'base-tile-layer',
        source: baseMapSource,
        visible: !panelProps.baseMapSetup.hidden,
      });
      const colorFilter = new CanvasFilter();
      //@ts-ignore
      baseLayer.addFilter(colorFilter);
      filterRef.current = colorFilter;
      const layers: Layer[] = [baseLayer];
      if (isGVNSP) {
        layers.push(
          new VectorLayer({
            className: 'GVNSP_RING',
            zIndex: GVNSP_MASK_ZINDEX,
            source: new VectorSource({
              features: [new Feature(new Circle([0, 0], EARTH_RADIUS))],
            }),
            style: new Style({
              renderer: (coordinates, state) => {
                drawCircleGradient({
                  coordinates,
                  state,
                  fromColor: panelProps.customBackgroundImage?.fromColor || 'black',
                  toColor: panelProps.customBackgroundImage?.toColor || 'black',
                  width: panelProps.customBackgroundImage?.width || 30,
                });
              },
            }),
          }),
        );
      }
      const extCalc = panelProps.properties?.boundingBox
        ? fromGeoBoxToBounds(JSON.parse(panelProps.properties?.boundingBox))
        : undefined;
      // const mapCenter = extCalc
      //   ? getCenter(extCalc)
      //   : fromLonLat([mapPosition!.longitude, mapPosition!.latitude], projectionCalc);
      const mapCenter = fromLonLat([mapPosition!.longitude, mapPosition!.latitude], projectionCalc);
      const isZoomEnabled = Boolean(mapsAllowedZoom[panelProps.id]) || !hasAnyWdLayerValue;
      mapRef.current = new Map({
        // target: panelProps.id + 'map',
        maxTilesLoading: Infinity,
        controls: [],
        layers,
        interactions: defaults({ doubleClickZoom: isZoomEnabled, dragPan: isZoomEnabled }),
        view: new View({
          center: mapCenter,
          zoom: isJustAdded ? 0 : mapPosition.zoom ? mapPosition.zoom : 0,
          rotation: 0,
          projection: projectionLike,
          zoomFactor: 2,
          constrainResolution: false,
          smoothExtentConstraint: false,
          extent: extCalc,
        }),
      });

      MapsRenderCache.set(panelProps.id, mapRef.current);

      drawSourceRef.current = new VectorSource({ wrapX: false });

      const vectorForDraw = new VectorLayer({
        source: drawSourceRef.current,
        zIndex: 999,
        className: 'drawing-layer',
      });
      mapRef.current.addLayer(vectorForDraw);

      mapRef.current.on('moveend', () => {
        const [lng, lat] = toLonLat(mapRef.current!.getView()!.getCenter()!, projectionCalc);
        const zoom = mapRef.current!.getView().getZoom();

        const [_, height] = mapRef.current?.getSize()!;
        const defaultZoom = zoomToZoomByRatio(zoom!, height / STUDIO_PANEL_HEIGHT);
        const rotation = mapRef.current!.getView().getRotation();
        dispatch(
          updateMapState({
            mapState: {
              latitude: lat,
              longitude: lng,
              zoom: zoom!,
              bearing: rotation,
              pitch: 0,
              defaultZoom,
            },
          }),
        );
      });

      mapRef.current!.on('rendercomplete', () => {
        if (isGVNSP) {
          const hasCoverUp = Boolean(getLayerByClassName('gvnsp-coverup', mapRef.current!));
          if (hasCoverUp) return;
          const { projectionCenterLat, projectionCenterLon } = panelProps.properties;
          const oceanPointDist = distance(
            Number(projectionCenterLat),
            Number(projectionCenterLon),
            85,
            0,
          );
          const southPolePointDist = distance(
            Number(projectionCenterLon),
            Number(projectionCenterLat),
            -85,
            0,
          );

          let point = OCEAN_NSPER_COORDINATE;
          if (oceanPointDist > southPolePointDist) {
            point = SOUTH_POLE_NSPER_COORDINATE;
          }
          const pixel = mapRef.current?.getPixelFromCoordinate(point)!;

          const baseLayerFound = getLayerByClassName('base-tile-layer', mapRef.current!);
          const imgData = baseLayerFound!.getData(pixel) as Uint8ClampedArray;
          if (!imgData) return;
          const r = imgData[0];
          const g = imgData[1];
          const b = imgData[2];
          const a = (imgData[3] / 255) * 100;

          mapRef.current?.addLayer(
            new VectorLayer({
              className: 'gvnsp-coverup',
              zIndex: -1,
              source: new VectorSource({
                features: [new Feature(new Circle([0, 0], EARTH_RADIUS))],
              }),
              style: new Style({
                fill: new Fill({
                  color: `rgba(${r},${g},${b},${a})`,
                }),
              }),
            }),
          );
        }
      });

      mapRef.current.on('click', (e) => {
        cacheMapClick({ mapId: panelProps.id, coord: e.coordinate });
        // handleCopyDrawingOnDblClick(e);
      });

      mapRef.current.once('rendercomplete', () => {
        dispatch(setMapsRendered({ mapId: panelProps.id, rendered: true }));
      });
    }

    if (shouldRenderMap) {
      mapRef.current.setTarget(panelProps.id + 'map');
      const currentZoom = mapRef.current.getView().getZoom();
      const defaultZoom = zoomToZoomByRatio(
        currentZoom!,
        MAX_FULLSCREEN_HEIGHT / STUDIO_PANEL_HEIGHT,
      );

      if (isJustAdded) {
        setFullScreen();
        dispatch(
          updateMapLayer({
            activeScene,
            elementId: panelProps.id,
            propertyPath: 'mapPositionControl.zoom',
            newValue: currentZoom,
          }),
        );
        dispatch(
          updateMapLayer({
            activeScene,
            elementId: panelProps.id,
            propertyPath: 'mapPositionControl.defaultZoom',
            newValue: defaultZoom,
          }),
        );
        // Temporary commented out, usnure what this is supposed to help with
        // UPDATE: this is used when a user selects custom bounds when adding map
        // UPDATE 2: added setTimeout to fix NIMA-2080 and keep NIMA-1995 fixed
        // UPDATE 3: for NIMA-2080 and NIMA-2181 we will calculate custom bounds center inside MapPicker from Geometry
        // setTimeout(() => {
        //   const [currLon, currLat] = toLonLat(mapRef.current?.getView().getCenter()!, projectionCalc);
        //   dispatch(
        //     updateMapLayer({
        //       activeScene,
        //       elementId: panelProps.id,
        //       propertyPath: 'mapPositionControl.longitude',
        //       newValue: currLon,
        //     }),
        //   );
        //   dispatch(
        //     updateMapLayer({
        //       activeScene,
        //       elementId: panelProps.id,
        //       propertyPath: 'mapPositionControl.latitude',
        //       newValue: currLat,
        //     }),
        //   );
        // }, 1);

        if (panelProps.flyOver?.keyFrames?.length) {
          const existingFlyovers = cloneDeep(panelProps.flyOver.keyFrames);
          existingFlyovers[0].mapPositionControl = {
            ...existingFlyovers[0].mapPositionControl!,
            zoom: currentZoom!,
            defaultZoom,
          };

          dispatch(
            updateMapLayer({
              activeScene,
              elementId: panelProps.id,
              propertyPath: 'flyOver.keyFrames',
              newValue: existingFlyovers,
            }),
          );
        }
      }
    }
  }, [panelProps.id, shouldRenderMap]);
  useEffect(() => {
    /**Rerender GVNSP Ring */
    if (isGVNSP) {
      const ringLayer = getLayerByClassName('GVNSP_RING', mapRef.current!) as VectorLayer<
        VectorSource<Circle>
      >;
      if (ringLayer) {
        ringLayer.setStyle(
          new Style({
            renderer: (coordinates, state) => {
              drawCircleGradient({
                coordinates,
                state,
                fromColor: panelProps.customBackgroundImage?.fromColor || 'black',
                toColor: panelProps.customBackgroundImage?.toColor || 'black',
                width: panelProps.customBackgroundImage?.width || 30,
              });
            },
          }),
        );
      }
    }
  }, [
    isGVNSP,
    panelProps.customBackgroundImage?.fromColor,
    panelProps.customBackgroundImage?.toColor,
    panelProps.customBackgroundImage?.width,
  ]);
  useEffect(() => {
    /**Remove twice!!! reconsider this */
    return () => {
      mapRef.current
        ?.getOverlays()
        .getArray()
        .filter((o) => !o.getElement()?.className.includes('overlay-weather-type'))
        .forEach((o) => {
          mapRef.current?.removeOverlay(o);
          o.dispose();
        });
    };
  }, []);
  function onEnd(props: TranslateEvent) {
    const transFeatureProps = props.features.getArray()[0].getProperties();
    const currentCityPosters = cloneDeep(panelProps.cityPosters);
    const foundPoster = currentCityPosters.find((p) => p.id === transFeatureProps.cityPosterId);
    const [currPosLon, currPosLat] = fromLonLat(
      [foundPoster!.anchor.longitude, foundPoster!.anchor.latitude],
      projectionCalc,
    );
    const [startPosLon, startPosLat] = props.startCoordinate;
    const [endPosLon, endPosLat] = props.coordinate;
    const deltaLon = startPosLon - endPosLon;
    const deltaLat = startPosLat - endPosLat;
    const [lon, lat] = toLonLat([currPosLon - deltaLon, currPosLat - deltaLat], projectionCalc);
    foundPoster!.anchor.longitude = lon;
    foundPoster!.anchor.latitude = lat;
    dispatch(
      updateMapLayer({
        activeScene,
        elementId: panelProps.id,
        propertyPath: 'cityPosters',
        newValue: currentCityPosters,
      }),
    );
  }
  function onSelect(props: SelectEvent) {
    const featureProps = props.selected[0]?.getProperties();
    if (featureProps)
      dispatch(
        setElement({
          activeElement: featureProps.cityPosterId,
          activeProp: 'cityGeoPoster',
          mapId: panelProps.id,
        }),
      );
  }

  useHotkeys('ctrl+v, command+v', (ev) => {
    ev.preventDefault();
    handleCopyDrawingOnClick();
  });

  async function handleCopyDrawingOnClick() {
    /**Prevent copy on storyboard */
    if (mode === ModeEnum.PROJECT) return;
    try {
      const clipboardData = await navigator.clipboard.readText();
      if (!clipboardData) return;
      const clickCoord = getMapClickCoordinate({ mapId: panelProps.id });
      if (!clickCoord) return;
      const parsedData = JSON.parse(clipboardData);
      // const hasDrawingToCopy = parsedData && parsedData.drawingIdentify;
      const isDrawing = isCopyDrawingInput(parsedData);
      if (!isDrawing) return;
      // navigator.clipboard.writeText('');
      const layerToCopy = parsedData.layer;
      const oldId = layerToCopy.id;
      const gJson = JSON.parse(layerToCopy.drawingGeoJson);
      let existingCoordinates = gJson.features[0].geometry.coordinates;
      let existingArrayOfTurningPoints = gJson.features[0].properties.arrayOfTurningPoints;
      let prevClickedCoord = getCachedCoordinate({ mapId: parsedData.mapId, layerId: oldId });
      const isImage = gJson.features?.[0]?.properties.drawingType === DrawingTypeExternalEnum.IMAGE;

      if (!prevClickedCoord) {
        prevClickedCoord = existingCoordinates[0];
      }
      layerToCopy.id = v4();

      const isSameMapProjection =
        panelProps.baseMapSetup.baseMapConfigurationProj4 === parsedData.projectionString;
      /**Image has one point go to double clicked coordinate, no translating reprojecting etc */
      if (!isImage) {
        if (!isSameMapProjection) {
          /**Translate coordinates array of turning points and prevClickedCoordinate */
          const copyMapProjectionCalc = `${parsedData.projection}:${parsedData.mapId}`;
          proj4.defs(copyMapProjectionCalc, parsedData.projectionString);
          register(proj4);

          const reprojectedCoordinates = existingCoordinates.map((coords: Coordinate) =>
            transform(coords, copyMapProjectionCalc, mapRef.current!.getView().getProjection()),
          );
          if (existingArrayOfTurningPoints) {
            const reprojectedArrayOfTurningPoints = existingArrayOfTurningPoints.map(
              (coords: Coordinate) =>
                transform(coords, copyMapProjectionCalc, mapRef.current!.getView().getProjection()),
            );
            existingArrayOfTurningPoints = reprojectedArrayOfTurningPoints;
          }

          prevClickedCoord = transform(
            prevClickedCoord,
            copyMapProjectionCalc,
            mapRef.current!.getView().getProjection(),
          );
          existingCoordinates = reprojectedCoordinates;
        }

        const translatedCoordinates = getTranslatedCoordinates(
          existingCoordinates,
          prevClickedCoord,
          clickCoord,
        );
        if (existingArrayOfTurningPoints) {
          const translatedArrayOfTurningPoints = getTranslatedCoordinates(
            existingArrayOfTurningPoints,
            prevClickedCoord,
            clickCoord,
          );
          gJson.features[0].properties.arrayOfTurningPoints = translatedArrayOfTurningPoints;
        }

        gJson.features[0].geometry.coordinates = translatedCoordinates;
      } else {
        /**Image */
        gJson.features[0].geometry.coordinates = clickCoord;
      }

      const stringifiedgJson = JSON.stringify(gJson);
      layerToCopy.drawingGeoJson = stringifiedgJson;
      const timestamp = calculateTimestampFromIndicator(panelProps.wdSpace[0].indicator);
      layerToCopy.keyframes.push({
        geoJson: stringifiedgJson,
        timestamp,
        startTime: GlobalPlayerControl.getTime(),
      });
      dispatch(copyMapDrawingLayer({ drawing: layerToCopy, mapId: panelProps.id, activeScene }));
      const features = new GeoJSON().readFeatures(gJson);
      dispatch(
        updateActiveDrawEdit({
          layerId: layerToCopy.id,
          newValue: features.map((f) => f.getProperties()),
        }),
      );
    } catch (e) {
      //
    }
  }
  function onSelectSymbolPoint(props: SelectEvent) {
    //@ts-ignore
    const coordinates = props.selected[0]?.getGeometry()!.getCoordinates();
    const layerId = props.selected[0]?.getId() as string;
    if (coordinates && mapRef.current) {
      const lonLat = toLonLat(coordinates, mapRef.current.getView().getProjection()!);
      if (lonLat) {
        dispatch(setSymbolPoint({ point: [lonLat[0].toFixed(2), lonLat[1].toFixed(2)] }));
        dispatch(
          setElement({
            activeElement: panelProps.id,
            activeProp: 'mapPanels',
          }),
        );
        dispatch(
          setLayerType({
            mapId: panelProps.id,
            layerType: 'symbol',
            layerId,
          }),
        );
      }
    }
  }

  useEffect(() => {
    const oceanUrl = panelProps.oceanMask?.baseMapUrl ? panelProps.oceanMask.baseMapUrl : undefined;
    if (!oceanUrl) return;

    const oceanSource = new XYZ({
      url: oceanUrl,
      crossOrigin: 'anonymous',
      minZoom: isMercator ? undefined : 4,
      cacheSize: Infinity,
      transition: 0,
      projection: projectionLike,
    });
    const allLayers = mapRef.current?.getAllLayers();
    const existingLayer = allLayers?.find((l) => l.getClassName() === 'ocean-layer');
    if (existingLayer && panelProps.oceanMask.hidden) {
      mapRef.current?.removeLayer(existingLayer);
      existingLayer.dispose();
    }
    const oceanLayer = new TileLayer({
      source: oceanSource,
      className: 'ocean-layer',
      zIndex: panelProps.oceanMask?.zindex,
      visible: !panelProps.oceanMask?.hidden,
    });
    if (oceanUrl && !existingLayer && panelProps.oceanMask && !panelProps.oceanMask.hidden)
      mapRef.current?.addLayer(oceanLayer);
  }, [
    isMercator,
    panelProps.oceanMask,
    panelProps.oceanMask?.baseMapUrl,
    panelProps.oceanMask?.hidden,
    panelProps.oceanMask?.inverted,
    projectionLike,
  ]);

  useEffect(() => {
    const allLayers = mapRef.current?.getAllLayers();
    const oceanLayer = allLayers?.find((l) => l.getClassName() === 'ocean-layer');
    if (oceanLayer && oceanLayer.getZIndex() !== panelProps.oceanMask?.zindex) {
      oceanLayer.setZIndex(panelProps.oceanMask?.zindex);
    }
  }, [panelProps.oceanMask?.zindex]);

  /*********************** Overlays (GeoPosters, point data) ***********************/
  const space = panelProps.wdSpace[0];

  const getActiveGeoPosters = useMemo(() => {
    return panelProps.geoPosters; /*?.filter(
      (gp) =>
        gp.enabled &&
        gp.timeControls.some(
          (tc) => tc.startMS <= contextValue.time && tc.endMS > contextValue.time,
        ),
    );*/
  }, [panelProps.geoPosters, contextValue.time]);
  const observedData = useMemo(() => {
    return space.observedDataLayers?.filter(
      (gp) =>
        gp.enabled &&
        gp.timeControls.some(
          (tc) => tc.startMS <= contextValue.time && tc.endMS > contextValue.time,
        ),
    );
  }, [space, contextValue.time]);
  const forecastData = useMemo(() => {
    return space.forecastDataLayers?.filter(
      (gp) =>
        gp.enabled &&
        gp.timeControls.some(
          (tc) => tc.startMS <= contextValue.time && tc.endMS > contextValue.time,
        ),
    );
  }, [space, contextValue.time]);
  function isObserved(obj: any): obj is ObservedWDElementDef {
    return 'observedWDSource' in obj;
  }
  function isForecast(obj: any): obj is ForecastWDElementDef {
    return 'forecastWDSource' in obj;
  }
  useEffect(() => {
    [...getActiveGeoPosters, ...observedData, ...forecastData]?.forEach((overlay) => {
      const el = document.getElementById(overlay.id + panelProps.id);
      if (isObserved(overlay)) {
        const { longitude, latitude } = overlay.observedWDSource.station;
        const isAlreadyAdded = Boolean(mapRef.current?.getOverlayById(overlay.id + panelProps.id));
        if (!isAlreadyAdded)
          mapRef.current?.addOverlay(
            new Overlay({
              element: el!,
              position: fromLonLat([longitude, latitude], projectionCalc),
              id: overlay.id + panelProps.id,
            }),
          );
      } else if (isForecast(overlay)) {
        const { longitude, latitude } = overlay.forecastWDSource.location;
        const isAlreadyAdded = Boolean(mapRef.current?.getOverlayById(overlay.id + panelProps.id));
        if (!isAlreadyAdded)
          mapRef.current?.addOverlay(
            new Overlay({
              element: el!,
              position: fromLonLat([longitude, latitude], projectionCalc),
              id: overlay.id + panelProps.id,
            }),
          );
      } else {
        const { longitude, latitude } = overlay;
        const isAlreadyAdded = Boolean(mapRef.current?.getOverlayById(overlay.id + panelProps.id));
        if (!isAlreadyAdded)
          mapRef.current?.addOverlay(
            new Overlay({
              element: el!,
              position: fromLonLat([longitude, latitude], projectionCalc),
              id: overlay.id + panelProps.id,
            }),
          );
      }
    });
    const existingOverlays = mapRef.current
      ?.getOverlays()
      .getArray()
      .filter((o) => !o.getElement()?.className.includes('overlay-weather-type'));
    if (existingOverlays) {
      existingOverlays.forEach((overlay) => {
        if (
          ![...getActiveGeoPosters, ...observedData, ...forecastData].some(
            (p) => p.id + panelProps.id === overlay.getId(),
          )
        ) {
          mapRef.current?.removeOverlay(overlay);
          overlay.dispose();
        }
      });
    }
  }, [getActiveGeoPosters, observedData, forecastData, space, panelProps.id]);
  /****************** Overlays (GeoPosters, point data) finished ******************/
  useEffect(() => {
    setCoordinates({
      x: panelProps.positionControl.x ? panelProps.positionControl.x : 0,
      y: panelProps.positionControl.y ? panelProps.positionControl.y : 0,
      w: panelProps.positionControl.w ? panelProps.positionControl.w : 0,
      h: panelProps.positionControl.h ? panelProps.positionControl.h : 0,
      zindex: panelProps.positionControl.zindex ? panelProps.positionControl.zindex : 0,
      rotation: panelProps.positionControl.rotation ? panelProps.positionControl.rotation : 0,
    });
  }, [
    panelProps.positionControl.x,
    panelProps.positionControl.y,
    panelProps.positionControl.w,
    panelProps.positionControl.h,
    panelProps.positionControl.zindex,
  ]);

  useEffect(() => {
    if (mapPosition.updateView) {
      mapRef.current
        ?.getView()
        .setCenter(fromLonLat([mapPosition!.longitude, mapPosition!.latitude], projectionCalc));
      mapRef.current?.getView().setZoom(mapPosition.zoom ? mapPosition.zoom : 0);
    }
  }, [
    mapPosition.latitude,
    mapPosition.longitude,
    mapPosition.zoom,
    mapPosition.updateView,
    mapPosition.flyToInitialPosition,
  ]);
  const asyncGraticules = async (onDrag?: boolean) => {
    await handleGraticules(mapRef.current, panelProps, onDrag);
  };

  useEffect(() => {
    mapRef?.current?.on('moveend', () => {
      asyncGraticules(true);
    });
    asyncGraticules();
  }, [
    panelProps?.graticule?.enabled,
    panelProps?.graticule?.strokeColor,
    panelProps?.graticule?.strokeWidth,
    panelProps?.graticule?.longitudeInterval,
    panelProps?.graticule?.latitudeInterval,
    panelProps?.graticule?.zindex,
    panelProps?.graticule?.enableLabels,
  ]);

  useEffect(() => {
    if (!panelProps.flyOverEnabled && mapPosition.updateView) {
      mapRef.current
        ?.getView()
        .setCenter(fromLonLat([mapPosition.longitude, mapPosition.latitude], projectionCalc));
      mapRef.current?.getView().setZoom(mapPosition.zoom);
    }
  }, [panelProps.flyOverEnabled]);

  useEffect(() => {
    if (panelProps.flyOverEnabled) {
      const keyframes = panelProps.flyOver?.keyFrames;
      const sorted = cloneDeep(keyframes!).sort((a, b) => a.timeInMS - b.timeInMS);
      const positionToFly = getCurrentMapPosition(activeTime, sorted, mapPosition);
      if (positionToFly) {
        mapRef.current?.getView().animate({
          center: fromLonLat([+positionToFly?.longitude, +positionToFly?.latitude], projectionCalc),
          zoom: +positionToFly?.zoom,
          duration: 0,
        });
      }
    }
  }, [activeTime, panelProps.flyOverEnabled]);

  useWeatherDataLayers(panelProps, mapRef.current!, contextValue);
  useSymbolLayers(
    panelProps,
    mapRef.current!,
    contextValue,
    symbolPointSelectRef,
    onSelectSymbolPoint,
  );
  useEventLayers(panelProps, mapRef.current!, contextValue);
  useCityPosterLayers(
    mapRef,
    panelProps,
    cityStyle,
    cityStyleSelect,
    MAX_FULLSCREEN_HEIGHT,
    activeAspectRatio,
    translateRef,
    citySelectRef,
    onEnd,
    onSelect,
  );
  useDrawingTools(
    panelProps,
    mapRef.current!,
    contextValue,
    activeAspectRatio,
    mode,
    projectToPlay,
  );

  useEffect(() => {
    renderVectorIsolines(
      panelProps,
      mapRef.current!,
      contextValue,
      mode,
      isSequence ? project : projectToPlay,
      skips,
      activeFramerate,
    );
  }, [panelProps.wdSpace[0].gribMapLayers, contextValue.time, mode, skips, activeFramerate]);
  /************ handle drawings interaction  here *******/
  useEffect(() => {
    if (clickSelectInteractionRef.current) {
      mapRef.current?.removeInteraction(clickSelectInteractionRef.current);
      clickSelectInteractionRef.current.dispose();
    }
    const select = new Select({
      condition: (ev) => {
        return singleClick(ev);
      },
      // toggleCondition: shiftKeyOnly,
      layers: (props) => {
        return props.getClassName()?.includes(`drawing-layer-`);
      },
    });
    clickSelectInteractionRef.current = select;
    mapRef.current?.addInteraction(select);
    select.on('select', (event) => {
      if (event.selected.length > 0) {
        const selectedFeature = event.selected[0];
        const selectedCoordinate = event.mapBrowserEvent?.coordinate;

        if (selectedCoordinate) {
          const layer = panelProps?.drawingElements.find(
            (draw) =>
              JSON.parse(draw.drawingGeoJson).features[0].properties.featureId ===
              selectedFeature?.getProperties()?.featureId,
          );

          if (layer) {
            cacheCoordinate({ mapId: panelProps.id, layerId: layer.id, coord: selectedCoordinate });
          }
        }
        !!selectedFeature.getProperties().featureId &&
          dispatch(
            setElement({
              activeElement: selectedFeature.getProperties().featureId,
              activeProp: 'drawLayer',
            }),
          );
        !!selectedFeature.getProperties().featureId &&
          dispatch(
            setActiveMap({
              mapId: panelProps.id,
            }),
          );
        setTimeout(() => {
          const selectedFeatures = select.getFeatures();
          selectedFeatures.clear();
        }, 50);
      }
    });
  }, [panelProps.drawingElements?.length]);

  useEffect(() => {
    mapRef.current &&
      handleDrawingLayersInteractions(
        mapRef.current!,
        panelProps.drawingElements,
        drawingLayersSelects,
        drawedLayerSelectedFeatureRef,
        snapInteractionsRef,
        dispatch,
        drawingLayersTranslates,
        drawingLayersModifies,
        activeDraw,
        startTranslateCoordinateRef,
        activeAspectRatio,
      );
  }, [panelProps.drawingElements, activeDraw]);

  let isShiftKeyPressed = false;
  useEffect(() => {
    document.addEventListener('keydown', (event) => {
      if (event.key === 'Shift') {
        isShiftKeyPressed = true;
      }
    });
    document.addEventListener('keyup', (event) => {
      if (event.key === 'Shift') {
        isShiftKeyPressed = false;
      }
    });
  });
  useEffect(() => {
    mapRef.current?.on('singleclick', (e: MapBrowserEvent<MouseEvent>) => {
      const currentCityPosters = panelProps.cityPosters;
      const cityToCopy = cloneDeep(
        panelProps.cityPosters.find((poster) => poster.id === activeElement),
      );
      const [lon, lat] = toLonLat(e.coordinate, projectionCalc);
      if (isShiftKeyPressed && cityToCopy) {
        cityToCopy!.anchor.longitude = lon;
        cityToCopy!.anchor.latitude = lat;
        cityToCopy.id = v4();
        dispatch(
          updateMapLayer({
            activeScene,
            elementId: panelProps.id,
            propertyPath: 'cityPosters',
            newValue: [...currentCityPosters, cityToCopy],
          }),
        );
        dispatch(setElement({ activeElement: panelProps.id, activeProp: 'mapPanels' }));
      }
    });
  });

  useEffect(() => {
    if (!mapRef.current) return;
    const existingLayers = mapRef.current
      .getLayers()
      .getArray()
      .filter((l) => l.getClassName()?.includes('base-map-layer-'));
    const currClassNames = currentLayers
      .filter((ly) => ly.enabled)
      .map((l) => `base-map-layer-${l.type}`);
    existingLayers.forEach((el) => {
      if (!currClassNames.includes(el.getClassName())) {
        mapRef.current?.removeLayer(el);
        el.dispose();
      }
    });

    function isCustom(obj: any): obj is CustomVectorLayer {
      return (
        typeof obj === 'object' && obj !== null && 'customVectorDef' in obj && obj.customVectorDef
      );
    }
    function isBase(obj: any): obj is VectorMapLayer {
      return typeof obj === 'object' && obj !== null && 'type' in obj && obj.type;
    }
    currentLayers
      .filter(isBase)
      .filter((ly) => ly.enabled && !ly.customVectorDefTemplate)
      .forEach((l) => {
        addBaseVectorLayer(l, mapRef.current!, projectionLike, projString, panelProps);
      });
    const customLayersToShow = currentLayers
      .filter(isCustom)
      .filter((ly) => ly.enabled && !!ly.customVectorDef);
    cleanUpCustomVectorLayers(customLayersToShow, mapRef.current!);
    customLayersToShow.forEach((l) => {
      addCustomVectorLayer(l, mapRef.current!, projectionLike, projString);
    });
  }, [currentLayers, panelProps, projString, projectionLike]);

  useEffect(() => {
    const oceanLayer = getOcean(mapRef.current!);
    if (oceanMask) {
      oceanLayer?.setOpacity(1);
    } else {
      oceanLayer?.setOpacity(0);
    }
  }, [oceanMask]);

  const flyOver = () => {
    if (!panelProps.flyOverEnabled) return;
    const keyframes = panelProps.flyOver?.keyFrames;
    if (!keyframes || !keyframes.length) return;
    const sorted = cloneDeep(keyframes).sort((a, b) => a.timeInMS - b.timeInMS);

    /**On start set to zero keyframe or initial position */
    if (contextValue.time === 0) {
      const [first] = sorted;
      const positionToFly =
        first?.timeInMS == 0
          ? fromLonLat(
              [+first.mapPositionControl?.longitude!, +first.mapPositionControl?.latitude!],
              projectionCalc,
            )
          : fromLonLat([+mapPosition.longitude, +mapPosition.latitude], projectionCalc);
      const zoomToFly = first?.timeInMS == 0 ? +first.mapPositionControl?.zoom! : +mapPosition.zoom;

      mapRef.current?.getView().animate({
        center: positionToFly,
        zoom: zoomToFly,
        duration: 0,
      });
    }
    const [current, next] = getPlayKeyframes(contextValue.time, sorted, mapPosition);
    // @ts-ignore
    if (shouldFly(current, next) && !mapRef.current?.getView().getAnimating()) {
      // @ts-ignore
      const dur = +next.time - +current?.time;
      const distance = distanceBetweenPoints(
        // @ts-ignore
        fromLonLat([current?.longitude, current?.latitude], projectionCalc),
        // @ts-ignore
        fromLonLat([next?.longitude, next?.latitude], projectionCalc),
      );
      mapRef.current?.getView().animate({
        // @ts-ignore
        center: fromLonLat([+next?.longitude, +next?.latitude], projectionCalc),
        duration: dur,
      });
      if (next?.transitionType === 'PARABOLIC') {
        mapRef.current?.getView().animate(
          {
            zoom: getMinusZoom(distance, Math.min(+next.zoom!, +current!.zoom!)),
            duration: dur / 2,
          },
          {
            // @ts-ignore
            zoom: +next.zoom,
            duration: dur / 2,
          },
        );
      }
      if (next?.transitionType === 'LINEAR') {
        mapRef.current?.getView().animate({
          // @ts-ignore
          zoom: +next.zoom,
          duration: dur,
        });
      }
    }
  };
  useEffect(() => {
    contextValue.isPlaying === 'PLAYING' && flyOver();
  });

  useEffect(() => {
    if (contextValue.isPlaying === PlaybackEnum.PAUSE) {
      mapRef.current?.getView().cancelAnimations();
    }
    if (contextValue.isPlaying === PlaybackEnum.STOP && panelProps.flyOverEnabled) {
      mapRef.current?.getView().cancelAnimations();
      const keyframes = panelProps.flyOver?.keyFrames;
      const sorted = cloneDeep(keyframes!).sort((a, b) => a.timeInMS - b.timeInMS);
      const [first] = sorted;
      const positionToFly =
        first?.timeInMS == 0
          ? fromLonLat(
              [+first.mapPositionControl?.longitude!, +first.mapPositionControl?.latitude!],
              projectionCalc,
            )
          : fromLonLat([+mapPosition.longitude, +mapPosition.latitude], projectionCalc);
      const zoomToFly = first?.timeInMS == 0 ? +first.mapPositionControl?.zoom! : +mapPosition.zoom;
      mapRef.current?.getView().animate({
        center: positionToFly,
        zoom: zoomToFly,
        duration: 0,
      });
    }
  }, [contextValue.isPlaying, panelProps.flyOverEnabled]);

  useEffect(() => {
    if (!activeDraw.enabled) {
      removeDrawIntercations();
    }

    if (activeDraw.enabled && activeDraw.drawingType) {
      let type: Type = 'LineString';
      let geometryFunction: GeometryFunction | undefined = undefined;
      switch (activeDraw.drawingType) {
        case DrawingTypeEnum.FRONTS: {
          type = 'LineString';
          geometryFunction = geometryFunctionForFronts;
          break;
        }
        // eslint-disable-next-line sonarjs/no-duplicated-branches
        case DrawingTypeEnum.ARROW: {
          type = 'LineString';
          geometryFunction = geometryFunctionForFronts;
          break;
        }
        case DrawingTypeEnum.CLOSED_CURVED_LINE: {
          type = 'LineString';
          geometryFunction = geometryFunctionForCurvedClosedLine;
          break;
        }
        case DrawingTypeEnum.RECTANGLE: {
          type = 'Circle';
          geometryFunction = createBox();
          break;
        }
        // eslint-disable-next-line sonarjs/no-duplicated-branches
        case DrawingTypeEnum.LINE_STRING: {
          type = 'LineString';
          geometryFunction = geometryFunctionForFronts;
          break;
        }
        case DrawingTypeEnum.POLYGON: {
          type = 'Polygon';
          geometryFunction = undefined;
          break;
        }
        case DrawingTypeEnum.CIRCLE: {
          type = 'Circle';
          geometryFunction = undefined;
          break;
        }
        case DrawingTypeEnum.IMAGE: {
          type = 'Point';
          geometryFunction = undefined;
          break;
        }
        default: {
          throw new Error('Draw type missmatch');
        }
      }
      addDrawInteraction(type, geometryFunction);
    }
  }, [activeDraw, activeScene]);

  useEffect(() => {
    const existingDefs = cloneDeep(panelProps.drawingElements);
    const indicator = panelProps.wdSpace[0].indicator || [];
    const timestamp = calculateTimestampFromIndicator(indicator);

    Object.entries(activeDrawEdit).forEach(([layerId, state]) => {
      state.forEach((st) => {
        const layer = getLayerByClassName(`drawing-layer-${layerId}`, mapRef.current!);
        const feature = getFeatureById(st.featureId, layer as VectorLayer<VectorSource<Geometry>>);

        if (feature) {
          /**Will pick it up from feature, activeDraw may be obsolete */
          // Object.entries(st).forEach(([key, val]) => {
          //   if (key !== 'save' && key !== 'geometry' && key !== 'arrayOfTurningPoints')
          //     feature.setProperties({ [key]: val });
          // });
          const defToChange = existingDefs.find((d) => d.id == layerId);
          let isChanged = false;

          const defsLayer = getLayerByClassName(
            `drawing-layer-${defToChange?.id}`,
            mapRef.current!,
          );
          if (defsLayer) {
            /**On translate intercation end and modify intercation end this will be set to indicate that layer is changed */
            isChanged = defsLayer.get('changedByUser');
            defsLayer.setProperties({ changedByUser: false });
          }

          if (!isChanged) return;
          /**Layer is changed in translate or modify */
          if (layer && defToChange && (layer as VectorLayer<VectorSource>)!.getSource()) {
            const gJson = new GeoJSON().writeFeatures(
              (layer as VectorLayer<VectorSource>)!.getSource()!.getFeatures(),
              {
                dataProjection: 'EPSG:4326',
                featureProjection: 'EPSG:4326',
              },
            );
            const parsedJson = JSON.parse(gJson);
            parsedJson.panelId = panelProps.id;
            addCircleToGeoJson(parsedJson);

            const currentTime = GlobalPlayerControl.getTime();
            const finalGeojson = JSON.stringify(parsedJson);
            defToChange.drawingGeoJson = finalGeojson;

            // Check the current time, and based on that add it to keyframes
            if (defToChange.keyframes && defToChange.keyframes.length > 0) {
              // support both timestamps and start time
              let idx = defToChange.keyframes.findIndex(
                /**+/2 2ms to avoid creating new keyframe because of rounding errors */
                (x) => x.timestamp >= timestamp - 2 && x.timestamp <= timestamp + 2,
              );

              if (timestamp === 0) {
                idx = defToChange.keyframes.findIndex((x) => x.startTime == currentTime);
              }
              if (idx > -1) {
                defToChange.keyframes[idx].geoJson = finalGeojson;
              } else {
                const { currentGeojson, nextGeojson, firstFrameTime, nextFrameTime } =
                  calculateDrawingKeyframeRefactor(
                    defToChange,
                    panelProps.wdSpace[0].indicator,
                    panelProps.wdSpace[0].timeframeIndicatorStep,
                  );

                let isInterpolatedChanged = true;
                // interpolate it here too and compare? but only if not playing
                // Since changedByUser introduced maybe not needed TO BE DONE
                if (contextValue.isPlaying != PlaybackEnum.PLAYING) {
                  isInterpolatedChanged = isInterpolatedDrawingChanged(
                    gJson,
                    currentGeojson,
                    nextGeojson,
                    firstFrameTime,
                    nextFrameTime,
                  );
                }

                // only create a keyframe if there is a difference in json
                if (
                  gJson != currentGeojson &&
                  finalGeojson != currentGeojson &&
                  isInterpolatedChanged
                ) {
                  defToChange.keyframes.push({
                    startTime: currentTime,
                    geoJson: finalGeojson,
                    timestamp,
                  });
                  if (timestamp) {
                    defToChange.keyframes.sort((a, b) => a.timestamp - b.timestamp);
                  } else {
                    defToChange.keyframes.sort((a, b) => a.startTime - b.startTime);
                  }
                }
              }
            }
          }
        }
      });
    });
  }, [activeDrawEdit]);

  const geometryFunctionForFronts: GeometryFunction = (coordinates, geometry) => {
    if (!geometry) {
      counterOfMainCoordinates.current = 0;
      geometry = new LineString([]);
      arrayOfTurningPoints.current.push(coordinates[0] as Coordinate);
      counterOfMainCoordinates.current += 1;
    }
    if (coordinates.length > counterOfMainCoordinates.current) {
      arrayOfTurningPoints.current.push(coordinates[coordinates.length - 1] as Coordinate);
      counterOfMainCoordinates.current += 1;
    }
    const curved = getSmoothLineString(coordinates);
    geometry.setCoordinates(curved);
    return geometry;
  };

  function addDrawInteraction(type: Type, geometryFunction: GeometryFunction | undefined) {
    removeDrawIntercations();
    drawRef.current = new Draw({
      source: drawSourceRef.current!,
      type,
      geometryFunction,
    });

    const drawLayer = getLayerByClassName('drawing-layer', mapRef.current!);
    drawRef.current.on('drawend', (event) => {
      // Called when a new drawing is added (not on modify)
      const feature = event.feature;
      const geometry = feature.getGeometry();
      const isCircle = geometry instanceof Circle;
      if (isCircle) {
        event.feature.set('circleCenter', geometry.getCenter());
        event.feature.set('circleRadius', geometry.getRadius());
      }
      setFeatureProperties(
        feature,
        activeDraw,
        arrayOfTurningPoints.current,
        mapRef.current?.getView()?.getResolution(),
      );
      const feat = new GeoJSON().writeFeatures([feature]);
      setTimeout(() => onDrawSave(feat), 20);
      arrayOfTurningPoints.current = [];
    });

    (drawLayer as VectorLayer<VectorSource>).setStyle(
      getStyleFunctionDraw(mapRef.current!, activeAspectRatio),
    );
    mapRef.current?.addInteraction(drawRef.current);
  }

  function removeDrawIntercations() {
    mapRef.current
      ?.getInteractions()
      .getArray()
      .forEach((i) => {
        if (i instanceof Draw) {
          mapRef.current?.removeInteraction(i);
          i.dispose();
        }
      });
  }

  function onDrawSave(feat: string) {
    const indicator = panelProps.wdSpace[0].indicator || [];
    const timestamp = calculateTimestampFromIndicator(indicator);
    const layer = getLayerByClassName('drawing-layer', mapRef.current!);
    const source = layer.getSource() as VectorSource;
    if (source) {
      const parsedJson = JSON.parse(feat);
      parsedJson.panelId = panelProps.id;
      addCircleToGeoJson(parsedJson);
      // Will always be only one feature
      const featureProperties = parsedJson.features[0]?.properties;
      let name = featureProperties?.drawingType || 'Drawing Layer';
      if (name === 'Front') {
        name += ` - ${featureProperties.frontType}`;
      }
      if (name === 'Image' && featureProperties.name) {
        name += ` - ${featureProperties.name}`;
      }

      dispatch(
        setActiveDraw({
          newValue: false,
          path: 'enabled',
        }),
      );
      drawSelectedFeatureRef.current = null;
      const elementToAdd = new DrawingDef();
      elementToAdd.timeControls = panelProps.timeControls;
      // this is where a new drawing is added
      // leave the old method of adding to drawingGeoJson so it works in VR until animations are implemented
      elementToAdd.drawingGeoJson = JSON.stringify(parsedJson);
      elementToAdd.keyframes = [
        {
          geoJson: JSON.stringify(parsedJson),
          startTime: GlobalPlayerControl.getTime(),
          timestamp,
        },
      ];
      elementToAdd.name = name;
      const newDrawings = [...panelProps.drawingElements, elementToAdd];
      const features = new GeoJSON().readFeatures(elementToAdd.drawingGeoJson);
      dispatch(
        updateActiveDrawEdit({
          layerId: elementToAdd.id,
          newValue: features.map((f) => f.getProperties()),
        }),
      );
      dispatch(
        updateMapLayer({
          activeScene,
          elementId: panelProps.id,
          newValue: newDrawings,
          propertyPath: 'drawingElements',
        }),
      );
    }
    drawSourceRef.current?.forEachFeature((f) => {
      drawSourceRef.current?.removeFeature(f);
      f.dispose();
    });
  }

  const draggingStopped = (data: DraggableData) => {
    const newX = transformAbsoluteToPercent(
      data.x,
      activeAspectRatio,
      'width',
      MAX_FULLSCREEN_HEIGHT,
    );
    const newY = transformAbsoluteToPercent(
      data.y,
      activeAspectRatio,
      'height',
      MAX_FULLSCREEN_HEIGHT,
    );
    setCoordinates({
      ...coordinates,
      x: newX,
      y: newY,
    });
    onEdit({
      ...panelProps,
      positionControl: { ...coordinates, x: newX, y: newY },
    });
  };
  const dragging = (data: DraggableData) => {
    const { x, y } = data;
    setPosition({
      ...gridPosition,
      x: transformAbsoluteToPercent(x, activeAspectRatio, 'width', MAX_FULLSCREEN_HEIGHT),
      y: transformAbsoluteToPercent(y, activeAspectRatio, 'height', MAX_FULLSCREEN_HEIGHT),
    });
  };
  const resizingStopped = (
    e: MouseEvent | TouchEvent,
    direction: any,
    ref: HTMLElement,
    delta: ResizableDelta,
    position: Position,
  ) => {
    const newW = transformAbsoluteToPercent(
      ref.offsetWidth,
      activeAspectRatio,
      'width',
      MAX_FULLSCREEN_HEIGHT,
    );
    const newH = transformAbsoluteToPercent(
      ref.offsetHeight,
      activeAspectRatio,
      'height',
      MAX_FULLSCREEN_HEIGHT,
    );
    setCoordinates({
      ...coordinates,
      w: newW,
      h: newH,
      x: transformAbsoluteToPercent(position.x, activeAspectRatio, 'width', MAX_FULLSCREEN_HEIGHT),
      y: transformAbsoluteToPercent(position.y, activeAspectRatio, 'height', MAX_FULLSCREEN_HEIGHT),
    });
    onEdit({
      ...panelProps,
      positionControl: {
        ...coordinates,
        w: newW,
        h: newH,
        x: transformAbsoluteToPercent(
          position.x,
          activeAspectRatio,
          'width',
          MAX_FULLSCREEN_HEIGHT,
        ),
        y: transformAbsoluteToPercent(
          position.y,
          activeAspectRatio,
          'height',
          MAX_FULLSCREEN_HEIGHT,
        ),
      },
    });
    mapRef.current?.updateSize();
  };
  const willBeShown = (
    currTime: number,
  ): {
    visible: boolean;
    end: number;
    start: number;
    inAnimationDuration: number;
    outAnimationDuration: number;
    inAnimation: FC<any>;
    outAnimation: FC<any>;
  } => {
    for (let i = 0; i < [panelProps.timeControls].length; i++) {
      if (
        panelProps.timeControls[i].startMS <= currTime &&
        panelProps.timeControls[i].endMS >= currTime
      ) {
        return {
          visible: true,
          end: panelProps.timeControls[i].endMS,
          start: panelProps.timeControls[i].startMS,
          // @ts-ignore
          inAnimation: panelProps.timeControls[i].inAnimationDef,
          // @ts-ignore
          outAnimation: panelProps.timeControls[i].outAnimationDef,
          inAnimationDuration: panelProps.timeControls[i].inAnimationDuration
            ? panelProps.timeControls[i].inAnimationDuration
            : 0,
          outAnimationDuration: panelProps.timeControls[i].outAnimationDuration
            ? panelProps.timeControls[i].outAnimationDuration
            : 0,
        };
      }
    }
    return {
      visible: false,
      end: 0,
      start: 0,
      // @ts-ignore
      inAnimation: AnimationsEnum.CUT,
      // @ts-ignore
      outAnimation: AnimationsEnum.CUT,
      inAnimationDuration: 0,
      outAnimationDuration: 0,
    };
  };
  const setFullScreen = () => {
    setCoordinates({
      ...coordinates,
      w: 100,
      h: 100,
      x: 0,
      y: 0,
    });
    onEdit({
      ...panelProps,
      positionControl: {
        ...coordinates,
        w: 100,
        h: 100,
        x: 0,
        y: 0,
      },
    });
    setTimeout(() => {
      mapRef.current?.updateSize();
    }, 10);
  };

  if (!shouldRenderMap) return null;

  return (
    <>
      <div
        style={{
          visibility: lines ? 'visible' : 'hidden',
          position: 'absolute',
          top: transformPercentToAbsolute(
            gridPosition.y,
            activeAspectRatio,
            'height',
            MAX_FULLSCREEN_HEIGHT,
          ),
          left: 0,
          right: 0,
          height: 1,
          borderBottom: '1px dashed white',
        }}
      />
      <div
        style={{
          visibility: lines ? 'visible' : 'hidden',
          position: 'absolute',
          top: 0,
          left: transformPercentToAbsolute(
            gridPosition.x,
            activeAspectRatio,
            'width',
            MAX_FULLSCREEN_HEIGHT,
          ),
          bottom: 0,
          width: 1,
          borderRight: '1px dashed white',
        }}
      />
      <div
        style={{
          visibility: lines ? 'visible' : 'hidden',
          position: 'absolute',
          top:
            transformPercentToAbsolute(
              gridPosition.y,
              activeAspectRatio,
              'height',
              MAX_FULLSCREEN_HEIGHT,
            ) +
            transformPercentToAbsolute(
              coordinates.h,
              activeAspectRatio,
              'height',
              MAX_FULLSCREEN_HEIGHT,
            ),
          left: 0,
          right: 0,
          height: 1,
          borderTop: '1px dashed white',
        }}
      />
      <div
        style={{
          visibility: lines ? 'visible' : 'hidden',
          position: 'absolute',
          left:
            transformPercentToAbsolute(
              gridPosition.x,
              activeAspectRatio,
              'width',
              MAX_FULLSCREEN_HEIGHT,
            ) +
            transformPercentToAbsolute(
              coordinates.w,
              activeAspectRatio,
              'width',
              MAX_FULLSCREEN_HEIGHT,
            ),
          top: 0,
          bottom: 0,
          width: 1,
          borderLeft: '1px dashed white',
        }}
      />
      <Rnd
        id={panelProps.id}
        data-type={'mapPanels'}
        scale={scaleFactor}
        style={{
          zIndex:
            activeDraw.enabled && activeDraw.drawingType
              ? 999
              : activeElement === panelProps.id && bringToFront
              ? 20
              : panelProps.positionControl.zindex,
          textAlign: 'center',
          visibility: isVisible(),
        }}
        disableDragging={mode === ModeEnum.PROJECT || disabled}
        enableResizing={mode === ModeEnum.SEQUENCE && !disabled}
        dragHandleClassName={'MapElement__dragHandle'}
        className={`${style.canvasElement} ${
          mode === ModeEnum.SEQUENCE && !disabled ? style.elementHover : null
        } ${
          activeElement === panelProps.id && mode === ModeEnum.SEQUENCE && !disabled
            ? style.active
            : null
        }`}
        bounds={'parent'}
        onDragStop={(e, data) => {
          setLines(false);
          draggingStopped(data);
        }}
        onDrag={(e, data) => dragging(data)}
        onResizeStop={(e, direction, ref, delta, position) =>
          resizingStopped(e, direction, ref, delta, position)
        }
        size={{
          width: transformPercentToAbsolute(
            coordinates.w,
            activeAspectRatio,
            'width',
            MAX_FULLSCREEN_HEIGHT,
          ),
          height: transformPercentToAbsolute(
            coordinates.h,
            activeAspectRatio,
            'height',
            MAX_FULLSCREEN_HEIGHT,
          ),
        }}
        position={{
          x: transformPercentToAbsolute(
            coordinates.x,
            activeAspectRatio,
            'width',
            MAX_FULLSCREEN_HEIGHT,
          ),
          y: transformPercentToAbsolute(
            coordinates.y,
            activeAspectRatio,
            'height',
            MAX_FULLSCREEN_HEIGHT,
          ),
        }}
      >
        <Transition
          // @ts-ignore
          inType={willBeShown(contextValue.time).inAnimation}
          inOptions={{
            duration: willBeShown(contextValue.time).inAnimationDuration,
            enterTime: willBeShown(contextValue.time).start,
            currentTime: contextValue.time,
          }}
          // @ts-ignore
          outType={willBeShown(contextValue.time).outAnimation}
          outOptions={{
            duration: willBeShown(contextValue.time).outAnimationDuration,
            exitTime: willBeShown(contextValue.time).end,
            currentTime: contextValue.time,
          }}
        >
          <>
            {getActiveGeoPosters?.map((poster) => (
              <div key={poster.id + panelProps.id}>
                <div id={poster.id + panelProps.id}>
                  <PosterElement
                    sceneId={sceneId}
                    disabled={disabled}
                    key={poster.id}
                    panelProps={poster}
                    isMapOverlay={true}
                    parentMapId={panelProps.id}
                    mapRef={mapRef}
                    parentTime={panelProps.timeControls}
                  />
                </div>
              </div>
            ))}
            {forecastData?.map((poster) => (
              <div key={poster.id + panelProps.id}>
                <div id={poster.id + panelProps.id}>
                  <ForecastElement
                    sceneId={sceneId}
                    key={poster.id}
                    disabled
                    panelProps={poster}
                    isMapOverlay={true}
                    mapId={panelProps.id}
                    canvas={{ cnvWidth: undefined, cnvHeight: undefined }}
                    mapRef={mapRef}
                  />
                </div>
              </div>
            ))}
            {observedData?.map((poster) => (
              <div key={poster.id + panelProps.id}>
                <div id={poster.id + panelProps.id}>
                  <ObservedElement
                    sceneId={sceneId}
                    key={poster.id}
                    panelProps={poster}
                    isMapOverlay={true}
                    mapId={panelProps.id}
                    canvas={{ cnvWidth: undefined, cnvHeight: undefined }}
                  />
                </div>
              </div>
            ))}
            <SymbolLayerWeatherType mapPanel={panelProps} />
            <SymbolLayerFontContainer symbolLayers={symbolLayers} />
            <CityPosterLayerFontContainer cityPosters={panelProps?.cityPosters} />
            <div
              id={panelProps.id + 'map'}
              ref={mapRefContainer}
              draggable={false}
              className={[styles['map-wrap'], isGVNSP ? styles.gvnspBackground : ''].join(' ')}
            />
          </>
        </Transition>
      </Rnd>
    </>
  );
};
export default MapElementBaseMerc;
